// initial state
const state = () => ({
  api_token: null,
  token_type: 'Bearer',
  currentPage: 'login'
})

// getters
const getters = {
  isLogged: (state) => !!state.api_token,
  getToken: (state) => state.api_token,
  getTokenType: (state) => state.token_type
}

// actions
const actions = {}

// mutations
const mutations = {
  setApiToken(state, token) {
    if(!!token) {
      state.api_token = token
    }
  },
  setTokenType(state, type) {
    if(!!type) {
      state.token_type = type
    }
  },
  deleteApiAuth(state) {
    state.api_token = null
    state.token_type = 'Bearer'
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}