<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ innerCompany.company }}</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-row>
            <h3>Options du magasin ouvert</h3>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="innerCompany.settings.open_title" label="Titre (ouvert)" outlined />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="innerCompany.settings.open_text" label="Description (ouvert)" outlined />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="innerCompany.settings.open_cp"
                height="80"
                label="Affichage horraire (ouvert)"
                auto-grow
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="innerCompany.settings.open_shippings"
                label="Méthodes de livraison (ouvert)"
                :items="shippingMethods"
                outlined
                multiple
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-row>
            <h3>Options du magasin fermé</h3>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="innerCompany.settings.close_title" label="Titre (fermé)" outlined />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="innerCompany.settings.close_text" label="Description (fermé)" outlined />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="innerCompany.settings.close_cp"
                height="80"
                label="Affichage horraire (fermé)"
                auto-grow
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="innerCompany.settings.close_shippings"
                label="Méthodes de livraison (fermé)"
                :items="shippingMethods"
                outlined
                multiple
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-row justify="center" align="center">
            Fermé
            <v-switch v-model="innerCompany.settings.is_open" inset class="pl-6 pr-3" />
            Ouvert
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row justify="center" align="center">
            Horraires désactivé
            <v-switch v-model="innerCompany.settings.auto_schedule" inset class="pl-6 pr-3" />
            Horraires auto
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" dense>
        <v-btn @click="$emit('save', innerCompany)">
          Save
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex"
import axios from "axios"

export default {
  name: 'CompanyDetails',
  props: {
    company: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      shippingMethods: [],
      innerCompany: this.company
    }
  },
  computed: {
    ...mapGetters('app', {
      getToken: 'getToken',
      getTokenType: 'getTokenType'
    })
  },
  mounted() {
    // Shipments methods
    axios.get(`/shippingMethods`, {
      headers: {
        Authorization: `${this.getTokenType} ${this.getToken}`
      }
    }, {
    }).then((res) => {
      if (!!res.data) {
        this.shippingMethods = res.data
      }
    })

    this.innerCompany.settings.open_shippings = this.company.settings.open_shippings.split(',').filter((e) => e !== '')
    this.innerCompany.settings.close_shippings = this.company.settings.close_shippings.split(',').filter((e) => e !== '')
  }
}
</script>