<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Horraires de {{ innerSchedules.company.company }}</v-toolbar-title>
      <v-spacer />
      <v-dialog v-model="modal" max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ titleDialog }}</span>
          </v-card-title>

          <v-card-text>
            <v-form ref="form" v-model="formValid">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select v-model="editedSchedule.day" :items="days" :rules="required" label="Jour" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="editedSchedule.open_hour" :rules="required" label="Heure d'ouverture" />
                    <v-time-picker v-model="editedSchedule.open_hour" format="24hr" />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="editedSchedule.close_hour" :rules="required" label="Heure de fermeture" />
                    <v-time-picker v-model="editedSchedule.close_hour" format="24hr" />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="modal=null; $refs.form.resetValidation()">
              Annuler
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveItem(editedSchedule)">
              Sauvegarder
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-container>
      <v-row justify="end">
        <v-btn color="secondary" @click="openNewItemDialog()">
          Nouvel Horraire
        </v-btn>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            v-model="selectedSchedules"
            :headers="schedulesHeaders"
            :items="innerSchedules.schedules"
            item-key="id"
            show-select
            class="elevation-1"
          >
            <template #item.day="{ item }">
              {{ days.find((d) => d.value === item.day).text }}
            </template>
            <template #item.active="{ item }">
              {{ !!item.active ? 'Activé' : 'Désactivé' }}
            </template>
            <template #item.actions="{ item }">
              <v-icon class="mr-2" @click="openEditItemDialog(item)">
                mdi-pencil
              </v-icon>
              <v-icon @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-btn @click="$emit('update-schedule', { items: selectedSchedules, action: 'on' })">
          Activer
        </v-btn>
        <v-btn @click="$emit('update-schedule', { items: selectedSchedules, action: 'off' })">
          Désactiver
        </v-btn>
      </v-row>
      <v-row justify="center">
        <v-btn color="secondary" @click="$emit('close')">
          Fermer
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex"
import axios from "axios"
import moment from "moment";

export default {
  name: 'CompanySchedule',
  props: {
    company: {
      type: Object,
      default: null
    },
    schedules: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      innerSchedules: this.schedules,
      formValid: false,
      required: [
        value => !!value || 'Champ requis'
      ],
      schedulesHeaders: [
        {
          text: 'Jour',
          align: 'start',
          sortable: true,
          value: 'day',
        },
        {
          text: 'Ouvre à',
          align: 'start',
          sortable: true,
          value: 'open_hour',
        },
        {
          text: 'Ferme à',
          align: 'start',
          sortable: true,
          value: 'close_hour',
        },
        { text: 'Statut', value: 'active' },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions'
        }
      ],
      days: [
        { value: 1, text: 'Lundi' },
        { value: 2, text: 'Mardi' },
        { value: 3, text: 'Mercredi' },
        { value: 4, text: 'Jeudi' },
        { value: 5, text: 'Vendredi' },
        { value: 6, text: 'Samedi' },
        { value: 7, text: 'Dimanche' },
      ],
      selectedSchedules: [],
      modal: false,
      titleDialog: 'Nouvel horraire',
      editedSchedule: {
        day: '',
        open_hour: '',
        close_hour: '',
        active: '1'
      }
    }
  },
  computed: {
    ...mapGetters('app', {
      getToken: 'getToken',
      getTokenType: 'getTokenType'
    })
  },
  methods: {
    openNewItemDialog() {
      this.titleDialog = 'Nouvel horraire'
      this.editedSchedule = {
        day: '',
        open_hour: '',
        close_hour: '',
        active: '1'
      }
      this.modal = true
    },
    openEditItemDialog(item) {
      this.titleDialog = "Modifier l'horraire"
      this.editedSchedule = item
      this.modal = true
    },
    saveItem(schedule) {
      if (this.$refs.form.validate()) {
        const data = JSON.parse(JSON.stringify(schedule))
        data.open_hour = moment(schedule.open_hour, "HH:mm:ss").utc().format("HH:mm:ss")
        data.close_hour = moment(schedule.close_hour, "HH:mm:ss").utc().format("HH:mm:ss")
        this.$emit(!!schedule.id ? 'update-schedule' : 'save-schedule', data)
        this.modal = false
      }
    },
    deleteItem(item) {
      confirm('Êtes-vous sûr de vouloir supprimer cet horraire ?')
        && this.$emit('delete-schedule', { schedule_id: item.id, company_id: item.company_id })
    }
  }
}
</script>