<template>
  <v-container fluid>
    <v-row justify="center">
      <!-- DETAILS -->
      <v-dialog
        :value="dialogDetails || dialogSchedules"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <CompanyDetails
          v-if="dialogDetails"
          :key="`company_info_${increment}`"
          :company="companyInfo"
          @close="closeDialogs()"
          @save="saveCompany($event)"
        />
        <CompanySchedule
          v-if="dialogSchedules"
          :key="`company_schedules_${increment}`"
          :schedules="companySchedules"
          @close="closeDialogs()"
          @save-schedule="saveSchedule($event)"
          @update-schedule="updateSchedule($event)"
          @delete-schedule="deleteSchedule($event)"
        />
      </v-dialog>
      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-row>
    <v-row>
      <v-container>
        <!-- DATAGRID -->
        <v-row>
          <v-col><h1>Compagnies</h1></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col offset-md="6" md="6">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="companies"
                :search="search"
                item-key="company_id"
                :items-per-page="15"
                show-select
                class="elevation-1"
              >
                <template #[`item.company`]="{ item }">
                  {{ item.company }} <v-btn icon :href="`https://restalamaison.ch/${item.seo_url}`" target="_blank">
                    <v-icon small>
                      mdi-open-in-new
                    </v-icon>
                  </v-btn>
                </template>
                <template #[`item.status`]="{ item }">
                  <template v-if="!!item.settings && item.settings.is_open">
                    Open
                  </template>
                  <template v-else>
                    Close
                  </template>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-row justify="space-around">
                    <v-btn @click="openDetails(item.company_id)">
                      Éditer
                    </v-btn>
                    <v-btn @click="openSchedules(item.company_id)">
                      Horraires
                    </v-btn>
                  </v-row>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="openAllStore()">
              Ouvrir la sélection
            </v-btn>
            <v-btn class="ml-3" @click="closeAllStore()">
              Fermer la sélection
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import {mapGetters} from "vuex";
import CompanyDetails from "@/components/CompanyDetails"
import CompanySchedule from "@/components/CompanySchedule"

export default {
  name: "CompaniesList",
  components: {
    CompanySchedule,
    CompanyDetails
  },
  data: () => ({
    selected: [],
    search: '',
    headers: [
      { text: 'ID', value: 'company_id' },
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'company',
      },
      { text: 'Status', value: 'status' },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        value: 'actions'
      }
    ],
    companies: [],
    loader: false,
    dialogDetails: false,
    dialogSchedules: false,
    companyInfo: {
      company: '',
      settings: {}
    },
    companySchedules: null,
    increment: 0
  }),
  computed: {
    ...mapGetters('app', {
      getToken: 'getToken',
      getTokenType: 'getTokenType'
    })
  },
  mounted() {
    this.getCompanies().then((res) => {
      if (!!res.data) {
        this.companies = res.data
        this.loader = false
      }
    }).catch((err) => {
      if(err.response) {
        if (err.response.status === 401) {
          this.$store.commit('app/deleteApiAuth')
        }
      }
    })
  },
  methods: {
    getCompanies() {
      this.loader = true
      return axios.get(`/companies`, {
        headers: {
          Authorization: `${this.getTokenType} ${this.getToken}`
        }
      })
    },
    refresh(){
      this.getCompanies().then((res) => {
        if (!!res.data) {
          this.companies = res.data
          this.loader = false
        }
      })
    },
    openDetails(id) {
      // Company details
      axios.get(`/companies/${id}`, {
        headers: {
          Authorization: `${this.getTokenType} ${this.getToken}`
        }
      }, {
      }).then((res) => {
        if (!!res.data) {
          this.companyInfo = res.data
          if(!this.companyInfo.settings) {
            this.companyInfo.settings = {
              open_title: '',
              close_title: '',
              open_text: '',
              close_text: '',
              open_shippings: '',
              close_shippings: '',
              is_open: 1,
              auto_schedule: 1
            }
          }
          this.dialogDetails = true
        }
      })
    },
    getSchedules(id) {
      return axios.get(`/companies/${id}/schedules`, {
        headers: {
          Authorization: `${this.getTokenType} ${this.getToken}`
        }
      }).then((res) => {
        if (!!res.data) {
          res.data.schedules = res.data.schedules.map((s) => {
            s.open_hour = moment.utc(s.open_hour, "HH:mm:ss").local().format("HH:mm:ss")
            s.close_hour = moment.utc(s.close_hour, "HH:mm:ss").local().format("HH:mm:ss")
            return s
          })
        }
        return res
      })
    },
    openSchedules(id) {
      this.getSchedules(id).then((res) => {
        if (!!res.data) {
          this.companySchedules = res.data
          this.dialogSchedules = true
        }
      })
    },
    saveCompany(data) {
      this.dialogDetails = false
      if(data !== null && data.settings !== null) {
        axios.put(`/companies/${data.company_id}`, data.settings, {
          headers: {
            Authorization: `${this.getTokenType} ${this.getToken}`
          }
        }).then(() => {
          this.refresh()
        })
      }
    },
    closeStore(data = null) {
      if(data !== null) {
        return axios.put(`/companies/${data.company_id}/close`, {}, {
          headers: {
            Authorization: `${this.getTokenType} ${this.getToken}`
          }
        })
      } else {
        Promise.resolve()
      }
    },
    openStore(data = null) {
      if(data !== null) {
        return axios.put(`/companies/${data.company_id}/open`, {}, {
          headers: {
            Authorization: `${this.getTokenType} ${this.getToken}`
          }
        })
      } else {
        Promise.resolve()
      }
    },
    closeAllStore() {
      if(this.selected.length > 0) {
        Promise.all(this.selected.map((c) => this.closeStore(c))).then(() => {
          this.selected = []
          this.refresh()
        })
      }
    },
    openAllStore() {
      if(this.selected.length > 0) {
        Promise.all(this.selected.map((c) => this.openStore(c))).then(() => {
          this.selected = []
          this.refresh()
        })
      }
    },
    closeDialogs() {
      this.dialogDetails = false
      this.dialogSchedules = false
    },
    saveSchedule(schedule) {
      if(!!schedule) {
        axios.post(`/companies/${this.companySchedules.company.company_id}/schedules`, schedule, {
          headers: {
            Authorization: `${this.getTokenType} ${this.getToken}`
          }
        }).then(() => {
          this.getSchedules(this.companySchedules.company.company_id).then((res) => {
            if (!!res.data) {
              this.companySchedules = res.data
              this.increment += 1
            }
          })
        })
      }
    },
    updateSchedule(data) {
      if(!!data.action) {
        // Mass update
        Promise.all(data.items.map((c) => {
          c.active = data.action === 'on' ? 1 : 0
          return this.updateSchedule(c)
        })).then(() => {
          this.getSchedules(this.companySchedules.company.company_id).then((res) => {
            if (!!res.data) {
              this.companySchedules = res.data
              this.increment += 1
            }
          })
        })
      } else {
        // Simple Update
        axios.put(`/schedules/${data.id}`, data, {
          headers: {
            Authorization: `${this.getTokenType} ${this.getToken}`
          }
        }).then((s) => {
          this.getSchedules(data.company_id).then((res) => {
            if (!!res.data) {
              this.companySchedules = res.data
              this.increment += 1
            }
          })
        })
      }
    },
    deleteSchedule(data) {
      axios.delete(`/schedules/${data.schedule_id}`, {
        headers: {
          Authorization: `${this.getTokenType} ${this.getToken}`
        }
      }).then(() => {
        this.getSchedules(data.company_id).then((res) => {
          if (!!res.data) {
            this.companySchedules = res.data
            this.increment += 1
          }
        })
      })
    }
  }
}
</script>

<style></style>