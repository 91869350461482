<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Logo Restalamaison"
          src="./assets/logo_121x120.png"
        />
      </div>
      <v-spacer />
      <v-btn
        v-if="isLogged"
        @click="disconnect()"
      >
        Se déconnecter
      </v-btn>
    </v-app-bar>

    <v-main>
      <LoginForm
        v-if="!isLogged"
        @login="login($event)"
      />
      <template v-else>
        <CompaniesList />
      </template>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

import LoginForm from "@/components/LoginForm";
import CompaniesList from "@/components/CompaniesList";

export default {
  name: 'App',

  components: {
    LoginForm,
    CompaniesList
  },

  data: () => ({
  }),

  computed: {
    ...mapGetters('app', {
      isLogged: 'isLogged'
    })
  },

  methods: {
    login(data) {
      axios.post(`/login`, {
        email: data.email,
        password: data.password
      }).then((res) => {
        if (!!res.data && !!res.data.access_token) {
          this.$store.commit('app/setApiToken', res.data.access_token)
          this.$store.commit('app/setTokenType', res.data.token_type)
        }
      }).catch((err) => {
        console.log('ERR', err.response)
      })
    },
    disconnect() {
      this.$store.commit('app/deleteApiAuth')
    }
  }
};
</script>
