import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import axios from 'axios'

Vue.config.productionTip = false

axios.defaults.withCredentials = true
// axios.defaults.baseURL = 'http://localhost:8000/api'
axios.defaults.baseURL = 'https://api.restalamaison.ch/api'

new Vue({
  vuetify,
  store: store,
  render: h => h(App)
}).$mount('#app')
